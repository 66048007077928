
import { defineComponent } from "vue";
import AdminRoleManager from "@/views/UserRoleManager/List.vue";

export default defineComponent({
  name: "List",
  components: {
    AdminRoleManager: AdminRoleManager
  }
});
